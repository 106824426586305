

export default {
    state: {
        currentStep : null,
        basket: null,
        listing: null,
        autoAcceptOffer: false,
        selectedCampaign: null,
        selectedPaymentType: null
    },
    getters: {
        currentStep: state => { return state.currentStep },
        basket: state => { return state.basket },
        listing: state => { return state.listing },
        autoAcceptOffer: state => { return state.autoAcceptOffer },
        selectedCampaign: state => { return state.selectedCampaign },
        selectedPaymentType: state => { return state.selectedPaymentType },
    },
    mutations: {
        setCurrentStep (state, currentStep) {
            
            state.currentStep = currentStep
        },
        setBasket (state, basket) {
            
            state.basket = basket
        },
        setListing (state, listing) {

            state.listing = listing
        },
        setAutoAcceptOffer (state, autoAcceptOffer) {
            
            state.autoAcceptOffer = autoAcceptOffer
        },
        updateCondition (state, condition) {

            let conditions = JSON.parse(state.basket.conditions)
            if (conditions == null)
                conditions = {}
            conditions[condition.name] = condition.val
            state.basket.conditions = JSON.stringify(conditions)
        },
        setSelectedCampaign (state, campaign) {

            state.selectedCampaign = campaign
        },
        setSelectedPaymentType (state, paymentType) {

            state.selectedPaymentType = paymentType
        }
    }
}

<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style>

@import '../public/assets/css/theme.min.css';
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.0/font/bootstrap-icons.css");

</style>
<script type="text/ecmascript-6">
export default {
    
    created() {

        this.$store.commit('initExperiments')        
        this.$tracking.setExperiments(this.$store.getters.experiments)
    }
}
</script>

<script type="text/ecmascript-6">

import BasketLayout from '@/layouts/BasketV2.vue'
import orderMixin from '@/mixins/order'
import merchantMixin from '@/mixins/merchant'
import AcceptedOffer from '@/components/basket/AcceptedOfferV2.vue'
import ProductCard from '@/components/basket/ProductCardV2.vue'
import faqMixin from "@/mixins/faq"
import MerchantLink from '@/components/basket/MerchantLink.vue'
import OrdersDashboard from '@/components/basket/OrdersDashboard.vue'

export default {

    components: {BasketLayout, AcceptedOffer, ProductCard, MerchantLink, OrdersDashboard}, 
    mixins: [orderMixin, merchantMixin, faqMixin],
    created() {
      
        this.$tracking.pageView('refused', 'order', {'Order ID': this.orderId})
    },
    computed: {
        isStatusRefused() {

            return this.isOrderLoaded && this.order.order_status_id == 9 ? true : false
        }
    }
}
</script>
<template>
    <basket-layout :withBreadCrumbs="false">
        <div v-if="isStatusRefused">
             <div class="row mt-3 mt-md-5">
                <div class="col-md-8">
                    <div class="col-md-11">
                        <h3>Produit refusé</h3>
                        <p>La reprise de votre produit a été annulée par notre partenaire {{order.reseller.name}}.</p>
                        <p>Après diagnostic, l’état de votre produit est jugé non conforme à votre déclaratif pour le motif suivant : "{{order.refused_reason}}".</p>
                        <p v-if="order.coupon_type == 'IMMEDIAT'">Conformément à nos CGU, vous allez être prélevé du montant de {{(order.payment_reservation.amount) | currencyInteger }} correspondants au montant de votre {{order.merchant.coupon_name}} que vous avez déjà reçu.</p>
                        <p>Afin de recupérer votre produit<sup>(1)</sup>, merci de nous contacter par mail : <a :href="'mailto:' + getContactEmail()">{{ getContactEmail() }}</a>.</p>
                        <p>A bientôt.</p>
                        <merchant-link :merchant="merchant" />
                        <p><small><sup>(1)</sup> Frais de retour à votre charge</small></p>
                    </div>
                </div>
                <div class="offset-md-0 col-md-4">
                    <div class="card shadow-lg w-100">
                        <product-card :basket="order" />
                        <accepted-offer :price="order.price" :merchant="order.merchant" :type="order.coupon_type" :bonus="order.brand_campaign" :paymentType="order.payment_type" />
                    </div>
                    <orders-dashboard />
                </div>
            </div>
        </div>
    </basket-layout>
</template>
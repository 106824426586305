import authService from '@/services/auth.js'

export default {

    state: {
        authenticated: false,
        user: null,
        merchant: null,
    },
    getters: {
        authenticated: state => { return state.authenticated },
        user: state => { return state.user },
        merchant: state => { return state.merchant },
        sellerId: state => { return state.user != undefined ? state.user.roles.find(element => element.app == 'Seller').seller_id : null}
    },
    mutations: {
        setAuthenticated (state, value) {
            
            state.authenticated = value
        },
        setUser (state, value) {
            
            if (value != undefined) {

                state.user = value
            }
        },
        setMerchant (state, merchant) {
            
            state.merchant = merchant
        },
    },
    actions: {
        
        async signIn({ dispatch }, credentials) {

            let response = await authService.getCSRF()
            if (response) {
            
                await authService.login(credentials.email, credentials.password, credentials.merchantId)
                this._vm.$tracking.logEvent('Logged In')
            }

            return dispatch('me')
        },
        async signOut ({ dispatch }) {

            await authService.logout()
    
            this._vm.$tracking.logEvent('Logged Out')

            return dispatch('me')
        },
        async register({ dispatch }, credentials) {

            let response = await authService.getCSRF()

            if (response) {

                await authService.register(

                    credentials.email, 
                    credentials.password, 
                    credentials.firstName, 
                    credentials.lastName, 
                    credentials.seller, 
                    credentials.phoneNumber,
                    credentials.merchantId,
                )
 
                this._vm.$tracking.logEvent('Registered In')
            }

            return dispatch('me')
        },
        me ({ commit }) {

            return authService.getCurrentUser()
                .then((response) => {

                    commit('setAuthenticated', true)
                    commit('setUser', response.data)

                    let user = response.data
                    let role = user.roles.find(element => element.app == 'Seller')
                    
                    this._vm.$tracking.setUserId(user.id)
                    
                    if (role) {

                        this._vm.$tracking.setUserProperties({'Seller ID': role.seller_id})
                    }

                    return response.data
                    
                }).catch((err) => {

                    console.log(err);
                    commit('setAuthenticated', false)
                    commit('setUser', null)

                    return null
            })
        }
    }
}
<script type="text/ecmascript-6">
export default {
    props: {
        'maxBestOffer': {
            'type': Object
        }, 
        'merchant': {
            'type' : Object
        }, 
        'hasBonus': {
            'type': Boolean, 
            'default': false
        },
        'isSelected': {
            'type': Boolean, 
            'default': false
        }
    },
    methods: {

        vouchSelected() {

            this.$emit('vouchSelected', { price: this.maxBestOffer.price, campaign: null, type: 'coupon' })
        }
    },
    created() {

        if (this.isSelected) {

            this.vouchSelected()
        }
    }
}
</script>

<template>
    <div class="d-flex flex-row mt-4 ms-3">
        <div class="card shadow-lg rounded-0 mx-0" :class="[hasBonus ? 'col-7' : 'col-md-7 col']">
            <div class="row mx-0 h-100">
                <div class="col-1 px-0 row">
                    <div class="col-7 bg-success px-0"></div>
                    <div class="col-5 px-0"></div>
                </div>
                <div class="col-1 d-flex justify-content-center align-items-center px-0">
                    <input class="form-check-input" type="radio" id="no-campaign" name="campaign" @click="vouchSelected" :checked="isSelected">
                </div>
                <div class="card-body col-10">
                    <p class="text-center mb-1">{{merchant.coupon_name.toUpperCase()}}<br/><strong>TOUS PRODUITS</strong></p>
                    <h2 class="text-success justify-content-center d-flex"><strong>{{maxBestOffer.price | currency}}*</strong></h2>
                    <p class="font-size-xxs mb-0 mx-3 text-gd-faint">Valable <strong>{{merchant.coupon_validity_period}} {{merchant.coupon_validity_products}} <span v-html="merchant.coupon_validity_places"></span></strong></p>
                </div>
            </div>
        </div>
    </div>
</template>
<script type="text/ecmascript-6">
import authService from '../../services/auth'
import AuthLayout from '../../layouts/Auth.vue'
import * as Sentry from "@sentry/vue";
import merchantMixin from '@/mixins/merchant'

export default {

    components: {AuthLayout},
    mixins: [merchantMixin],
    data() {

        return {

            email: '',
            password: '',
            passwordConfirmation: '',
            error: ''
        }
    },
    created() {

        this.getMerchantBySubdomain()
    },
    methods: {

        async resetPassword() {


            try {

                let response = await authService.getCSRF()
                if (response) {

                    await authService.resetPassword(this.token, this.email, this.password, this.passwordConfirmation, this.merchant.id)
                    this.$router.push({name: 'signIn'})
                }
            }
            catch (err) {

                if (err.response.status == 422) 
                    this.error = 'Veuillez mettre un mot de passe d\'au moins 8 caractères'
                else
                    this.error = 'Une erreur est survenue'
                
                Sentry.captureException(err)
            }
        }
    },
    computed: {

        token() {

            return this.$route.params.token
        }
    }
}
</script>
<template>
    <auth-layout>
        <div v-if="error != ''" class="alert alert-danger" role="alert">
            {{error}}
        </div>
        <form @submit.prevent="resetPassword">
            <div class="form-group">
                <input type="email" v-model="email" placeholder="email" class="form-control w-100" />
            </div>
            <div class="form-group mt-2">
                <input type="password" v-model="password" placeholder="nouveau mot de passe" class="form-control w-100" />
            </div>
            <div class="form-group mt-2">
                <input type="password" v-model="passwordConfirmation" placeholder="confirmation du mot de passe" class="form-control w-100" />
            </div>
            <div class="form-group mt-2">
                <button type="submit" class="btn btn-success w-100">Envoyer</button>
            </div>
        </form>
    </auth-layout>
</template>

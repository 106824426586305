<script type="text/ecmascript-6">

import Media from '@/mixins/media.js'
import Faq from '@/mixins/faq'
import Breadcrumbs from './BreadcrumbsV2.vue'
import merchantMixin from '@/mixins/merchant.js'

export default {
    data() {

        return {

            domainMerchant:null
        }
    },
    props: {
        withBreadCrumbs: {

            type: Boolean,
            default: true
        }
    },
    components: {Breadcrumbs},
    mixins: [Media, Faq, merchantMixin],
    computed: {

        logoSrc() {

            if (this.merchant !== null)
                return this.getMerchantAsset(this.merchant.id, this.merchant.asset_logo_name)
            
            return null
        },
        currentStep() {

            return this.$store.getters.currentStep
        },
        merchantId() {

            if (this.$store.getters.merchant !== null)
                return this.$store.getters.merchant.id

            return null
        },
        merchantCss() {

            return this.$store.getters.merchant != undefined && this.$store.getters.merchant.has_custom_css ? this.$store.getters.merchant.subdomain : 'customer'
        },
    },
    methods:{

        async loadMerchantByDomain() {
               
           this.domainMerchant = await this.getMerchantBySubdomain()
       },
    },
    created() {

        this.loadMerchantByDomain()
    }
}

</script>
<template>
    <header v-if="domainMerchant != null" :id="merchantCss+'-header'" class="navbar navbar-expand-lg shadow-lg">
        <div class="row w-100" id="header-container">
            <div class="offset-1 d-flex align-items-center justify-content-center px-0" id="left-section">
                <router-link :to="{name: 'searchForm'}" v-if="merchant.orders_history_url === 'greendid'" class="mx-md-0">
                    <img class="img" :src="logoSrc" />
                </router-link>
                <a v-else class="mx-md-0">
                    <img class="img" :src="logoSrc" />
                </a>
            </div>
            <div class="row mt-5"  id="middle-section">
                <div v-if="withBreadCrumbs">
                    <breadcrumbs :currentStep="currentStep" />
                </div>
            </div>
            <div class="px-0" id="right-section">
                <a class="navbar-brand" :id="merchantCss+'-logo'">
                    <img src="/assets/img/logo-greendid-2.png" class="img-fluid" />
                </a>
                <div id="contact-section">
                    <a :href="getFAQURL()" target="_blank" class="link d-flex align-items-center">
                        <i class="bi bi-question-circle mt-1 text-success"></i> 
                        <u class="font-size-xxs ms-1">Centre d'aide</u>
                    </a>
                    <div class="d-flex align-items-center">
                        <i class="bi bi-telephone mt-1 text-success"></i> 
                        <u class="font-size-xxs ms-1 mt-1">07 56 87 69 45</u>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<style scoped>

@import '/assets/css/styles/header.css';

</style>

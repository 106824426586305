import axios from 'axios'

export default {

    getBasket(basketPublicId) { 

        var path = '/api/seller/basket/' + basketPublicId

        return axios.get(path)
    },
    updateCondition(basketPublicId, conditions) { 

        var path = '/api/seller/basket/' + basketPublicId + '/conditions'

        return axios.patch(path, conditions)
    },
    getMaxBestOffer(basketPublicId) { 
        let path = '/api/seller/basket/' + basketPublicId + '/maxBestOffer';  

        return axios.get(path)
    },
    validateOffer(basketPublicId) {

        var path = '/api/seller/basket/' + basketPublicId + '/offer'

        return axios.patch(path)
    },
    create(uid, pid, mid, oid, desc, img, date, price) {

        let path = '/api/seller/basket'
        let data = {
            uid: uid,
            pid: pid,
            mid: mid,
            oid: oid,
            desc: desc,
            img: img,
            date: date,
            price: price
        }

        return axios.post(path, data)
    },
    createFromSearchForm(mid, uid, pid, oid, sid, img, source) {

        let path = '/api/seller/searchFormBasket'
        let data = {
            mid: mid,
            uid: uid,
            pid: pid,
            oid: oid,
            img: img,
            sid: sid,
            source: source
        }

        return axios.post(path, data)
    }
}


<script type="text/ecmascript-6">
    import BasketHeader from '../components/basket/HeaderV2.vue'
    import BasketFooter from '../components/basket/FooterV2.vue'
    import BasketReinsurance from '../components/ReinsuranceV2.vue'

    export default {
        components: {BasketHeader, BasketFooter, BasketReinsurance},
        props: {
            withBreadCrumbs: {

                type: Boolean,
                default: true
            }
        },
    }
</script>
<template>
    <div>
        <basket-header :withBreadCrumbs="withBreadCrumbs" />
        <div class="container">
            <slot/>
        </div>
        <basket-reinsurance />
        <basket-footer />
    </div>
</template>

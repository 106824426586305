<script type="text/ecmascript-6">
import BasketLayout from '@/layouts/BasketV2.vue'
import offerTemplateService from '@/services/offerTemplate.js'
import categoryService from '@/services/category.js'
import merchantMixin from '@/mixins/merchant'
import basketService from '@/services/basket'
import mediaMixin from '@/mixins/media'
import basketMixin from '@/mixins/basket'
import ProductCard from '@/components/basket/ProductCardSearchForm.vue'
import OrdersDashboard from '@/components/basket/OrdersDashboard.vue'
import { uuid } from 'vue-uuid'

export default {

    components: {BasketLayout, ProductCard, OrdersDashboard},
    mixins: [merchantMixin, mediaMixin, basketMixin],
    data() {

        return {

            facets: [],
            categories: [],
            selectedCategory: null,
            selectedFacets : {
                category_id: null,
                displayed_brand: '',
                family: '',
                sub_family: '',
                model: '',
                option: '',
                capacity: '',
                processor_generation: '',
                gpu: '',
                hard_drive: '',
                ram: null,
                diagonal: null,
                processor_family: '',
                display_type: '',
                monitor_diagonal: '',
                monitor_screenType: '',
                monitor_max_resolution: '',
                monitor_connectors: '',
                monitor_curve_type: '',
                monitor_has_speakers: '',
            },
            selectedItem: null,
            error: '',
            sorts: {
                displayed_brand: ['XIAOMI', 'SAMSUNG', 'LENOVO', 'HUAWEI', 'HP', 'DELL', 'ASUS', 'APPLE', 'ACER'],
                family: ['XPERIA XZ', 'XPERIA Z', 'GALAXY Z', 'GALAXY S', 'GALAXY NOTE'],
                ram: [8, 6, 4, 3, 2, 1],
                processor_family: ['Intel Core i9', 'Intel Core i7', 'Intel Core i5', 'Intel Core i3', 'AMD Ryzen 9', 'AMD Ryzen 7', 'AMD Ryzen 5', 'AMD Ryzen 3']
            }
        }
    },
    created() {

        this.$store.commit('setBasket', null)
        this.$store.commit('setSource', this.$route.query.source)
        this.$store.commit('setMerchantUserId', uuid.v4())

        if (this.merchantUserId != undefined) {

            this.loadCategories()
            this.loadFacets()
            this.setCurrentStep(10)
            this.$tracking.pageView('Category Choose', 'Search Form', {'Merchant User ID': this.merchantUserId})
        }
        else {

            this.$tracking.pageView('No Merchant User ID', 'Search Form')
        }
    },
    methods: {

        async loadFacets() {

            let response = await offerTemplateService.listFacets(this.selectedFacets)

            this.facets = this.sortFacets(response.data.facets)
            this.selectedItem = response.data.hit
        },
        async loadCategories() {

            let response = await categoryService.list()

            this.categories = response.data

            if (this.$route.query.cid != undefined) {

                let cid = this.$route.query.cid
                let cat = this.categories.find(category => category.id == cid)
                if (cat != undefined) {

                    this.selectedCategory = cat
                }
            }
        },
        sortFacets(facets) {

            for (const property in this.sorts) {
            
                const sorts = this.sorts
                
                facets[property].sort(function(a, b) {

                    const indexA = sorts[property].indexOf(a)
                    const indexB = sorts[property].indexOf(b)

                    if (indexA === -1 && indexB === -1) {
                        
                        return String(a).toLowerCase().localeCompare(String(b).toLowerCase())
                    }
                    
                    return indexB - indexA
                })
            }
    
            return facets
        },
        async validate() {

            let id = this.selectedItem.id
            let oid = null
            let pid = null
            let sid = null

            if (id.startsWith('offer_template')) {

                oid = id.replace('offer_template_', '')
            }
            else if (id.startsWith('product')) {

                pid = id.replace('product_', '')
            }
            else if (id.startsWith('specs')) {

                sid = id
            }

            try {

                let response = await basketService.createFromSearchForm(this.merchant.id, this.merchantUserId, pid, oid, sid, this.selectedItemImg, this.source)
                let data = response.data
                
                if (data.type === 'basket') {

                    this.$router.push({name: 'basketInfo', params: {id: data.basketId}})
                }
                else if (data.type === 'order') {

                    this.$router.push({name: 'order', params: {id: data.orderId}})
                }
            }
            catch (error) {

                this.error = 'CREATE_BASKET'
                this.$tracking.pageView('No offer found', 'Search Form', {'Merchant User ID': this.merchantUserId, 'Offer Displayed Brand': this.selectedItem.displayed_brand, 'Offer Family': this.selectedItem.family, 'Offer Sub Family': this.selectedItem.sub_family, 'Offer Model': this.selectedItem.model})
            }
        },
        isFacetSetted(facet) {

            return this.selectedFacets[facet] != undefined && this.selectedFacets[facet] != ''
        },
        canUnsetFacet(facet) {

            let nbrFacets = this.filteredFacets.length
            
            if (nbrFacets <= 0) return false

            let index = this.filteredFacets.indexOf(facet)
            if (this.selectedItem != undefined)
                return (index + 1) === nbrFacets

            return (index + 2) === nbrFacets
        },
        canUnsetCategory() {

            return this.filteredFacets.length === 1
        },
        unsetFacet(facet) {

            this.selectedFacets[facet] = ''
            this.selectedItem = null
            this.loadFacets()
            this.$tracking.logEvent('Search Form - Unset Facet', {'Merchant User ID': this.merchantUserId, 'Facet': facet})
        },
        unsetCategory() {

            this.selectedFacets.category_id = null
            this.selectedCategory = null
            this.selectedItem = null
            this.$tracking.logEvent('Search Form - Unset Category', {'Merchant User ID': this.merchantUserId})
        },
        unsetAll() {

            this.selectedFacets = {
                category_id: null,
                displayed_brand: '',
                family: '',
                sub_family: '',
                model: '',
                option: '',
                capacity: '',
                processor_generation: '',
                gpu: '',
                hard_drive: '',
                ram: null,
                diagonal: null,
                processor_family: '',
                display_type: '',
                monitor_diagonal: '',
                monitor_screenType: '',
                monitor_max_resolution: '',
                monitor_connectors: '',
                monitor_curve_type: '',
                monitor_has_speakers: ''
            }

            this.selectedItem = null
            this.selectedCategory = null
            this.error = ''
            this.$tracking.logEvent('Search Form - Unset All', {'Merchant User ID': this.merchantUserId})
        },
        formatValue(val, selectedFacet) {

            if (selectedFacet === 'ram') {

                return val + ' Go'
            }

            if (selectedFacet === 'diagonal') {

                return val.substring(0, val.indexOf('.')) + '"'
            }

            return val
        },
        trackFacetSelected(event, facet) {

            let value = event.target.value
            this.$tracking.logEvent('Search Form - Select Facet', {'Merchant User ID': this.merchantUserId, 'Facet': facet, 'Facet Value': value})
        },
        selectCategory(category) {

            if (category.is_only_async) {

                this.goToListing(category)
            }
            else {

                this.selectedCategory = category
            }
        },
        goToListing(category) {

            let query = {}

            if (category) {

                query['cid'] = category.id
            }

            if (this.source) {

                query['source'] = this.source
            }

            if (this.merchantUserId) {

                query['umid'] = this.merchantUserId
            }

            this.$router.push({name: 'listing', query: query})
        }
    },
    watch: {

        selectedCategory(val) {

            if (val != undefined) {

                this.selectedFacets.category_id = val.id
                this.loadFacets()
                this.$tracking.logEvent('Search Form - Select Category', {'Merchant User ID': this.merchantUserId, 'Category': val.name})
            }
        },
    },
    computed: {

        filteredFacets() {

            var filteredFacets = [
                'displayed_brand',
                'display_type',
                'diagonal',
                'processor_family',
                'processor_generation',
                'gpu',
                'hard_drive',
                'ram',
                'family',
                'sub_family',
                'model',
                'option',
                'capacity',
                'monitor_diagonal',
                'monitor_screen_type',
                'monitor_max_resolution',
                'monitor_connectors',
                'monitor_curve_type',
                'monitor_has_speakers'
            ]
            var toRemove = []
            if (this.facets != undefined) {

                let keys = Object.keys(this.facets)
                for (let i = 0; i < keys.length; i++) {

                    let facetName = keys[i]
                    if (this.facets[facetName].length <= 0) {

                        toRemove.push(facetName)
                    }
                }
            }

            filteredFacets = filteredFacets.filter(function(element) {

                return toRemove.includes(element) === false
            })

            var selectedFacets = this.selectedFacets
            filteredFacets = filteredFacets.filter(function(element, index) {

                if (index > 0) {

                    let prevFacet = filteredFacets[index - 1]
                    if (selectedFacets[prevFacet] == null || selectedFacets[prevFacet] == '') {

                        return false
                    }
                }

                return true
            })

            return filteredFacets
        },
        merchantUserId() {

            return this.$store.getters.merchantUserId
        },
        source() {

            return this.$store.getters.source
        },
        selectedItemImg() {

            if (this.selectedItem == undefined) return null

            if (this.selectedItem.img_url != undefined) return this.selectedItem.img_url

            return this.getCategoryAssetGeneric(this.selectedItem.category_id, this.selectedItem.displayed_brand)
        },
        selectedItemType() {

            if (this.selectedItem == undefined) return null

            let id = this.selectedItem.id

            if (id.startsWith('product')) return 'product'
            
            if (id.startsWith('offer_template')) return 'offer_template'

            if (id.startsWith('specs')) return 'specs'

            return null
        }
    }
}
</script>
<style scoped>
.subtitle {
    font-size: 0.6em;
    font-weight: normal;
}
</style>
<template>
    <basket-layout>
        <div v-if="merchantUserId" class="row mt-3 mt-md-5">
            <div class="col-md-8">
                <div class="col-md-11">
                    <div class="ps-3">
                        <h3>
                            Revendez vos produits high-tech
                            <br/>
                            <small class="subtitle">simplement, immédiatement et au meilleur prix</small>
                        </h3>
                    </div>
                    <div v-if="error == ''" class="card shadow-lg mt-4">
                        <div class="card-body">
                            <div v-if="selectedCategory == undefined" class="container row">
                                <div @click="selectCategory(category)" class="d-flex flex-column col col-sm-3 p-2 bg-white text-center" v-for="category in categories" :key="category.id" style="cursor:pointer;">
                                    <div class="mb-auto"><img class="img-fluid" style="max-height:120px" :src="getCategoryAsset(category.img_filename)" :alt="category.name" /></div>
                                    <div class="small mt-2"><strong>{{category.name}}</strong></div>
                                </div>
                            </div>
                            <div v-if="selectedCategory">
                                <div>
                                    <strong class="text-success">1</strong>. {{$t('SEARCH_FORM.QUESTIONS.category.question')}}
                                    <div class="mt-2 row">
                                        <div class="text-muted col-4">Type de produit</div>
                                        <div class="col-8 row">
                                            <div class="col col-10 g-0">
                                                <select class="w-100 p-1">
                                                    <option selected>{{selectedCategory.name}}</option>
                                                </select>
                                            </div>
                                            <div class="col-2 row g-0 text-center">
                                                <i class="bi bi-check text-success col-6 m-auto"></i>
                                                <i style="cursor:pointer" v-if="canUnsetCategory()" @click="unsetCategory()" class="bi bi-x text-danger m-auto fs-4 col-6"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-for="(selectedFacet, index) in filteredFacets" :key="selectedFacet" class="mt-4">
                                    <strong class="text-success">{{index + 2}}</strong>. {{$t('SEARCH_FORM.QUESTIONS.'+ selectedFacet+'.question')}}
                                    <div class="mt-2 row">
                                        <div v-if="isFacetSetted(selectedFacet)" class="text-muted col-4">Choisissez dans la liste</div>
                                        <div v-else class="text-muted col-4">{{$t('SEARCH_FORM.QUESTIONS.'+ selectedFacet+'.label')}}</div>
                                        <div class="col-8 row">
                                            <div class="col-10 g-0">
                                                <select class="w-100 p-1" :name="'select_'+selectedFacet" @change="loadFacets();trackFacetSelected($event, selectedFacet)" v-model="selectedFacets[selectedFacet]">
                                                    <option></option>
                                                    <option :value="val" v-for="val in facets[selectedFacet]" :key="val">{{formatValue(val, selectedFacet)}}</option>
                                                </select>
                                            </div>
                                            <div v-if="isFacetSetted(selectedFacet)" class="col-2 row g-0 text-center">
                                                <i class="bi bi-check text-success col-6 m-auto"></i>
                                                <i style="cursor:pointer" v-if="canUnsetFacet(selectedFacet)" @click="unsetFacet(selectedFacet)" class="bi bi-x text-danger m-auto fs-4 col-6"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="error == 'CREATE_BASKET'">
                        <div class="card shadow-lg mt-4">
                            <div class="card-body">
                                Nous n'avons pas d'offre pour ce produit pour le moment.
                            </div>
                        </div>
                        <button @click="unsetAll()" class="btn btn-success shadow-lg text-white w-100 text-center mt-3">RECOMMENCER</button>
                    </div>
                    <div class="d-flex mt-3">
                        <div v-if="selectedCategory && error == ''">
                            <button v-if="selectedItem == undefined" @click="goToListing(selectedCategory)" class="btn btn-info shadow-lg text-white btn-sm me-1">Je ne trouve pas mon produit</button>
                            <button @click="unsetAll()" class="btn btn-danger shadow-lg text-white btn-sm">Tout effacer</button>
                        </div>
                        <div v-if="selectedItem && error == ''" class="flex-grow-1 ms-2">
                            <button @click="validate()" class="btn btn-success shadow-lg text-white w-100">VALIDER MES CHOIX</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mt-3 mt-md-0">
                <div v-if="selectedItem" class="card shadow-lg w-100">
                    <product-card :brand="selectedFacets.displayed_brand" :description="selectedItem.label" :img="selectedItemImg" :itemType="selectedItemType"/>
                </div>
                <orders-dashboard />
            </div>
        </div>
        <div v-else class="alert alert-danger mt-4">
            Une erreur s'est produite.
        </div>
    </basket-layout>
</template>

<script type="text/ecmascript-6">

import BasketLayout from '@/layouts/BasketV2.vue'
import orderMixin from '@/mixins/order'
import merchantMixin from '@/mixins/merchant'
import faqMixin from '@/mixins/faq'
import AcceptedOffer from '@/components/basket/CounteredOffer.vue'
import ProductCard from '@/components/basket/ProductCardV2.vue'
import MerchantLink from '@/components/basket/MerchantLink.vue'

export default {

    components: {BasketLayout, AcceptedOffer, ProductCard, MerchantLink}, 
    mixins: [orderMixin, merchantMixin, faqMixin],
    created() {
      
        this.$tracking.pageView('counter offer refused', 'order', {'Order ID': this.orderId})
    },
    computed: {
        isStatusRefused() {

            return this.isOrderLoaded && this.order.order_status_id == 9 && this.order.refused_counter_offer != undefined ? true : false
        }
    }
}
</script>
<template>
    <basket-layout :withBreadCrumbs="false">
        <div v-if="isStatusRefused">
            <div class="row mt-3 mt-md-5">
                <div class="col-md-8 mb-5">
                    <div class="col-md-11">
                        <h3>Vente annulée</h3>
                        <p>Suite à votre refus de la contre-offre, votre vente est annulée.</p>
                        <p v-if="order.coupon_type == 'IMMEDIAT'">Conformément à nos CGU, vous allez être prélevé du montant de {{(order.price) | currency }} correspondants au montant de votre {{order.merchant.coupon_name}} que vous avez déjà reçu.</p>
                        <p>Afin de recupérer votre produit<sup>(1)</sup>, merci de nous contacter par mail : <a :href="'mailto:' + getContactEmail()">{{ getContactEmail() }}</a></p>.
                        <p>A bientôt.</p>
                        <merchant-link :merchant="merchant" />
                        <p><small><sup>(1)</sup> Frais de retour à votre charge</small></p>
                    </div>
                </div>
                <div class="offset-md-0 col-md-4">
                    <div class="card shadow-lg w-100">
                        <product-card :basket="order" />
                        <accepted-offer :counter="order.refused_counter_offer"/>
                    </div>
                </div>
            </div>
        </div>
    </basket-layout>
</template>
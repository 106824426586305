import orderService from '@/services/order'
import * as Sentry from "@sentry/vue";

export default {

    data() {
        return {
            order: null
        }
    },
    created() {
      
        let withCanBeImmediat = this.withCanBeImmediat && this.withCanBeImmediat === true ? true : false
        this.loadOrder(this.$route.params.id, withCanBeImmediat)
    },
    methods: {

        async loadOrder(orderPublicId, withCanBeImmediat = false) {

            try {

                let response = await orderService.get(orderPublicId, withCanBeImmediat);
                this.order = response.data
            }
            catch (error) {

                Sentry.captureException(error)
                this.$router.push({name: 'home'})
            }
        },
    },
    computed: {

        isOrderLoaded() {

            return this.order != null
        },
        orderId() {

            return this.$route.params.id
        }
    },
    watch: {

        isOrderLoaded() {

            let routeName = ''


            if (this.order.order_status_id == 2) {

                routeName = 'orderDelivery'
            }
            else if (this.order.order_status_id == 9) {

                if (this.order.refused_counter_offer != undefined)
                    routeName = 'orderCounterOfferRefused'
                else
                    routeName = 'orderRefused'
            }
           
            else if (this.order.order_status_id == 3) {

                routeName = 'orderSent'
            }
            else if (this.order.order_status_id == 4) {

                routeName = 'orderToValidate'
            }
            else if (this.order.order_status_id == 5) {

                routeName = 'orderConfirm'
            }
            else if (this.order.order_status_id == 6) {

                routeName = 'orderCounterOffer'
            }
            else if (this.order.order_status_id == 20) {

                routeName = 'orderExpired'
            }
            else if (this.order.order_status_id == 7) {

                routeName = 'orderStandBy'
            }
            else if (this.order.order_status_id == 21 && this.order.is_transfer_signed === 0) {

                routeName = 'orderDelivery'
            }
            
            if (routeName != '' && routeName != this.$route.name) {

                this.$router.push({name: routeName, query: this.$route.query})
            }
        }
    }
}
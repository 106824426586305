import merchantService from '@/services/merchant'

export default {

    computed: {

        merchant() {

            return this.$store.getters.merchant
        },
        isMerchantLoaded() {

            return this.$store.getters.merchant != undefined
        }
    },
    methods: {

        async getMerchantBySubdomain() {

            let subdomain = window.location.hostname.split('.')[0]
            if (subdomain != 'customer' || subdomain != 'b2b') {

                let response = await merchantService.getMerchantBySubdomain(subdomain)    

                this.$store.commit('setMerchant', response.data)

                return  response.data
            }

            return null
        }
    }
}

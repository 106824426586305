<script type="text/ecmascript-6">
    import { mapActions } from 'vuex'
    import BasketLayout from '@/layouts/BasketV2.vue'
    import basketMixin from '@/mixins/basket'
    import merchantMixin from '@/mixins/merchant'
    import faqMixin from '@/mixins/faq.js'
    import VuePhoneNumberInput from 'vue-phone-number-input';

    export default {

        mixins: [basketMixin, merchantMixin, faqMixin],
        components: {BasketLayout, VuePhoneNumberInput},
        data() {
            return {

                email: '',
                password: '',
                passwordConfirm: '',
                error: null,
                wantsToRegister: true,
                passwordNotOK: false,
                isLoading: false,
                domainMerchant: null,
                firstName: '',
                lastName: '',
                phoneNumber: '',
                phoneNumberValue: '',
                seller: {

                    name:'',
                    legalAddress:'',
                    legalAddressZipcode:'',
                    legalAddressCity:'',
                    legalCountry: '',
                    status:'',
                    city:'',
                    legalNumber:'',
                    vatNumber:'',
                },
                isCGUAccepted: false,
                sepaCountries : [
                    { name: "Autriche", id: "AT" },
                    { name: "Belgique", id: "BE" },
                    { name: "Chypre", id: "CY" },
                    { name: "Estonie", id: "EE" },
                    { name: "Finlande", id: "FI" },
                    { name: "France", id: "FR" },
                    { name: "Allemagne", id: "DE" },
                    { name: "Grèce", id: "GR" },
                    { name: "Irlande", id: "IE" },
                    { name: "Italie", id: "IT" },
                    { name: "Lettonie", id: "LV" },
                    { name: "Lituanie", id: "LT" },
                    { name: "Luxembourg", id: "LU" },
                    { name: "Malte", id: "MT" },
                    { name: "Pays-Bas", id: "NL" },
                    { name: "Portugal", id: "PT" },
                    { name: "Slovaquie", id: "SK" },
                    { name: "Slovénie", id: "SI" },
                    { name: "Espagne", id: "ES" },
                    ], 
            }
        },
        created() {

            this.$tracking.pageView('signin', 'basket', {'Basket ID': this.basketId})
            this.loadMerchantByDomain()
        },
        methods: {
            ...mapActions({
                signIn: 'signIn',
                register: 'register'
            }),
            async loadMerchantByDomain() {
                
                this.domainMerchant = await this.getMerchantBySubdomain()
            },
            async submitSignIn() {

                try {

                    await this.signIn({email: this.email, password: this.password, merchantId: this.domainMerchant.id})

                    let queryReturn = this.$route.query.return
                    if (queryReturn == null)

                        this.$router.replace({name: 'home'})
                    else
                        this.$router.replace(queryReturn)
                }
                catch (error) {

                    this.error = 'Impossible de se connecter'
                }
            },
            async submitRegister() {

                this.error = ''

                if (!this.isCGUAccepted) {

                    this.error = 'Veuillez accepter les CGU'
                    return
                }

                if (!this.isSellerOk()) {

                    this.error = 'Vous devez remplir tous les champs concernant votre société'
                    return
                }    

                if (this.password == this.passwordConfirm) {

                    try {
                        
                        await this.register({

                            email: this.email, 
                            password: this.password, 
                            lastName: this.lastName, 
                            firstName: this.firstName, 
                            phoneNumber: this.phoneNumber, 
                            seller: this.seller, 
                            merchantId: this.domainMerchant.id
                        })

                        this.$router.replace(this.$route.query.return)
                    }
                    catch (error) {

                        if (error.response.status == 500) {

                            this.error = 'Un compte Greendid existe déjà pour cet utilisateur ' + this.domainMerchant.name + '.'
                        }
                        else {
                            
                            let errors = error.response.data.errors
                            
                            if (Object.prototype.hasOwnProperty.call(errors, 'password') && errors.password.length > 0) {

                                this.error = 'Le mot de passe doit comporter 8 caractères ou plus'
                            }
                            else if (Object.prototype.hasOwnProperty.call(errors, 'email') && errors.email.length > 0) {

                                this.error = 'L\'email n\'est pas valide'
                            }
                        }
                    }
                }
                else {

                    this.error = 'Vos mots de passe ne sont pas identiques'
                }
            },
            togglePasswordVisibility(element) {

                if (element.type == 'password')
                    element.type = 'text'
                else
                    element.type = 'password'
            },
            toggleRegister(wantsToRegister) {

                this.error = null
                this.wantsToRegister = wantsToRegister
            },
            scrollUp() {

                window.scrollTo(0, 0)
            },
            isSellerOk() {

                Object.values(this.seller).forEach(element => {
                    
                    if (element.length === 0) {

                        return false
                    }
                });

                return true
            },
            updatePhoneNumber(data) {

                this.phoneNumber = data.e164
                this.isPhoneNumberValid = data.isValid
            },
        },
        computed: {

            subdomain() {

                return window.location.hostname.split('.')[0]
            },
            sortedSepaCountries() {

                return this.sepaCountries.concat().sort((elA, elB) => {
                    
                    if (elA.name < elB.name) {
                        return -1;
                    }
                    if (elA.name > elB.name) {
                        return 1;
                    }

                    return 0;
                })
            }
        }
    }
</script>
<style>
.input-tel__input,.country-selector__input {

    border-color: #dae1e7 !important;
}
</style>
<template>
    <basket-layout>
        <div v-if="domainMerchant" class="row mt-3 mt-md-5 m-4">
            <div class="ps-3">
                <h3>
                    Revendez votre matériel
                    <br/>
                    <small class="subtitle">simplement, immédiatement et au meilleur prix</small>
                </h3>
            </div>
            <div class="d-flex m-lg-4 mt-4 m-2 justify-content-space-between text-center text-lg-start flex-column flex-lg-row">
                <div class="card shadow-lg mt-4 col-lg-7">
                    <div class="card-body mx-4">
                        <ul class="nav nav-tabs justify-content-center mt-2" role="tablist">
                            <li class="nav-item text-center col-5">
                                <a href="#signup" class="nav-link" :class="{active: wantsToRegister}" data-toggle="tab" v-on:click="toggleRegister(true)" role="tab">Je crée un compte<br/><strong class="text-success">{{ domainMerchant.name.toUpperCase() }} x GREENDID</strong></a>
                            </li>
                            <li class="nav-item text-center col-5">
                                <a href="#signin" class="nav-link" :class="{active: !wantsToRegister}" data-toggle="tab" v-on:click="toggleRegister(false)" role="tab">J'ai déjà un compte<br/><strong class="text-success">{{ domainMerchant.name.toUpperCase() }} x GREENDID</strong></a>
                            </li>
                        </ul>
                        <div v-if="error" class="alert alert-danger" role="alert">
                            {{error}}
                        </div>
                        <div class="tab-content">
                            <div class="tab-pane" :class="{active: wantsToRegister, show: wantsToRegister, fade: !wantsToRegister}" id="signup" role="tabpanel">         
                                <form action="#" @submit.prevent="submitRegister">
                                    <div class="ms-3 mt-4">
                                        <!-- MON COMPTE -->
                                        <strong>Mon compte</strong>
                                    </div>
                                    <div class="form-group mt-2">
                                        <input class="w-100 form-control" id="email" required type="text" v-model="email" placeholder="Email">
                                    </div>
                                    <div class="form-group mt-3">
                                        <div class="input-group">
                                            <input ref="password1" class="form-control" required type="password" v-model="password" placeholder="Mot de passe">
                                            <div class="input-group-addon"><button @click="togglePasswordVisibility($refs.password1)" type="button" class="btn btn-icon"><i class="ci-eye" /></button></div>
                                        </div>
                                    </div>
                                    <div class="form-group mt-3">
                                        <div class="input-group">
                                            <input ref="password2" class="form-control" required type="password" v-model="passwordConfirm" placeholder="Confirmation du mot de passe">
                                            <div class="input-group-addon"><button @click="togglePasswordVisibility($refs.password2)" type="button" class="btn btn-icon"><i class="ci-eye" /></button></div>
                                        </div>
                                    </div>
                                    <!-- COORDONNEES -->
                                    <div class="ms-3 mt-5">
                                        <strong>Mes coordonnées</strong>
                                    </div>
                                    <div class="form-group mt-2 row">
                                        <div class="col"><input class="form-control" required type="text" v-model="firstName" placeholder="Prénom"></div>
                                        <div class="col"><input class="form-control" required type="text" v-model="lastName" placeholder="Nom"></div>
                                    </div>
                                    <div class="form-group mt-3">
                                        <vue-phone-number-input  v-model="phoneNumberValue" @update="updatePhoneNumber" :preferred-countries="['FR', 'BE', 'DE']" :default-country-code="'FR'" />
                                    </div>
                                   
                                    <!-- SELLER -->
                                    <div class="ms-3 mt-5">
                                        <strong>Ma société</strong>
                                    </div>
                                    <div class="form-group mt-2 row">
                                        <div class="col"><input class="form-control" required type="text" v-model="seller.name" placeholder="Raison Sociale"></div>
                                        <div class="col"><input class="form-control" required type="text" v-model="seller.legalNumber" placeholder="SIREN"></div>
                                    </div>
                                    <div class="form-group mt-2 row">
                                        <div class="col"><input class="form-control" required type="text" v-model="seller.city" placeholder="Ville d'enregistrement"></div>
                                        <div class="col"><input class="form-control" required type="text" v-model="seller.status" placeholder="Statut de ma société"></div>
                                        <div class="col"><input class="form-control" required type="text" v-model="seller.vatNumber" placeholder="Numéro de TVA"></div>
                                    </div>
                                    <div class="ms-3 mt-5">
                                        Coordonnées du siège social
                                    </div>
                                    <div class="form-group mt-2 row">
                                         <div class="col"><input class="form-control" required type="text" v-model="seller.legalAddress" placeholder="Adresse"></div>
                                        <div class="col"><input class="form-control" required type="text" v-model="seller.legalAddressCity" placeholder="Ville"></div>
                                    </div>
                                    <div class="form-group mt-2 row">
                                        <div class="col"><input class="form-control" required type="text" v-model="seller.legalAddressZipcode" placeholder="Code postal"></div>
                                        <div class="col">

                                            <select v-model="seller.legalCountry" class="cz-filter-search form-control">
                                                <option value="" disabled selected>Pays</option>
                                                <option v-bind:key="country.id" :value="country.id" v-for="country in sortedSepaCountries">
                                                    {{ country.name }}
                                                </option>
                                            </select>
                                        </div>

                                    </div>
                                    <div class="form-group mt-3">
                                        <div class="form-check">
                                            <input class="form-check-input border-success-lg" type="checkbox" name="input-cgu" v-model="isCGUAccepted">
                                            <label class="form-check-label">J'accepte les <a href="https://assets.greendid.com/docs/greendid_cgu.pdf" target="_blank">Conditions Générales d'Utilisation</a></label>
                                        </div>
                                    </div>
                                    <div class="form-group mt-3">
                                        <button class="btn btn-success w-100" type="submit">Créer mon compte</button>
                                    </div>
                                </form>
                            </div>
                            <div class="tab-pane" :class="{active: !wantsToRegister, show: !wantsToRegister, fade: wantsToRegister}" id="signin" role="tabpanel">
                                <form action="#" @submit.prevent="submitSignIn">
                                    <div class="form-group mt-3">
                                        <input required type="text" v-model="email" placeholder="Email" class="form-control w-100">
                                    </div>
                                    <div class="form-group mt-3">
                                        <input required type="password" v-model="password" placeholder="Mot de passe" class="form-control w-100">
                                    </div>
                                    <div class="form-group mt-3">
                                        <button class="btn btn-success w-100" type="submit">Se connecter</button>
                                    </div>
                                    <div class="form-group text-center mt-3">
                                        <router-link :to="{name: 'forgotPassword'}">J'ai oublié mon mot de passe</router-link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="height:fit-content" class="card col-lg-4 shadow-lg mt-4 text-uppercase">
                    <div class="m-3">
                        <h5>Comment ça marche ?</h5>
                        <div class="d-flex flex-column">
                            <div class="d-flex">
                                <img class="me-4" style="height:40px;width: 40px;" src="/assets/img/icons8-formulaire-64.png" alt="formulaire">
                                <strong class="me-4 text-success">1. </strong>
                                <p> <strong class="text-success"> Identifiez</strong><br> votre produit à revendre</p>
                            </div>
                            <div class="d-flex">
                                <img class="me-4" style="height:40px;width: 40px;" src="/assets/img/icons8-recu-euro-100.png" alt="offre immédiate">
                                <strong class="me-4 text-success">2. </strong> 
                                <p> Bénéficiez d'une<br> <strong class="text-success">offre immédiate</strong></p>
                            </div>
                            <div class="d-flex">
                                <img class="me-4" style="height:40px;width: 40px;" src="/assets/img/icons8-livraison-gratuite-100.png" alt="livraison gratuite">
                                <strong class="me-4 text-success">3.</strong> 
                                <p>Expédiez <strong class="text-success"> gratuitement</strong><br> votre produit</p>
                            </div>
                            <div class="d-flex">
                                <img class="me-4" style="height:40px;width: 40px;" src="/assets/img/icons8-transfert-d'argent-100.png" alt="transfert d'argent">
                                <strong class="me-4 text-success">4.</strong> 
                                <p><strong class="text-success" >Recevez les fonds</strong> après audit<br>sur votre <strong class="color-seller">{{ domainMerchant.coupon_name }}</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </basket-layout>
</template>

<style>

.justify-content-space-between {

    justify-content: space-between;

}
.justify-content-space-around {

    justify-content: space-around;

}

.color-seller {

    color: #71E4FC;
}
</style>

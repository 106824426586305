import axios from 'axios'

export default {

    listFacets(facets) {

        let path = '/api/seller/offerTemplates/facets?'

        if (facets != undefined) {

            let queryString = ''
            let facetNames = Object.keys(facets)
            facetNames.forEach(facetName => {
                
                if (facets[facetName] != null && facets[facetName] != '') {

                    if (queryString !== '') queryString += '&'
                    queryString += facetName + '=' + encodeURIComponent(facets[facetName])
                }
            });

            path = path + queryString
        }

        return axios.get(path)
    }
}

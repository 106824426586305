import axios from 'axios'

export default {

    list() {

        var path = '/api/seller/categories'

        return axios.get(path)
    }
}

<script type="text/ecmascript-6">

import BasketLayout from '../../layouts/BasketV2.vue'
import orderMixin from '@/mixins/order'
import merchantMixin from '@/mixins/merchant'
import FaqMixin from '@/mixins/faq'
import AcceptedOffer from '../../components/basket/AcceptedOfferV2.vue'
import ProductCard from '../../components/basket/ProductCardV2.vue'
import orderService from '../../services/order'
import SuccessAlert from '@/components/basket/SuccessAlert.vue'
import OrdersDashboard from '@/components/basket/OrdersDashboard.vue'

export default {

    components: {BasketLayout, AcceptedOffer, ProductCard, SuccessAlert, OrdersDashboard}, 
    mixins: [orderMixin, merchantMixin, FaqMixin],
    data() {

        return {

            isDownloading: false,
            error: '',
            pdfDelivery: null,
            pdfEstimate: null,
            estimateDownloaded: false,
            estimateBtnLoading: false,
            deliveryBtnLoading: false,
            deliveryDownloaded: false,
        }
    },
    created() {
      
        if (this.$route.query.l == 1) {

            this.estimateBtnLoading = true
            this.deliveryBtnLoading = true
        }

        this.$store.commit('setCurrentStep', 50)
    },
    methods: {

        async downloadDeliveryNote() {

            this.deliveryBtnLoading = true

            try {

                var response = await orderService.getDeliveryNote(this.order.public_id, this.isMobile)

                if (response.data.url) {

                    this.deliveryDownloaded = true;
    
                    const link = document.createElement('a');
                    link.href = response.data.url;
                    link.target = '_blank';
                    link.download = 'delivery_'+this.merchant.invoice_prefix+'_'+this.order.public_id+'.pdf'; // 'estimate_'+merchantPrefix+'_'+orderPublicId+'.pdf'
                    link.click();
                    window.URL.revokeObjectURL(response.data.url);
    
                    this.deliveryBtnLoading = false
                }
            }
            catch (e) {

                this.error = 'Une erreur est survenue lors de la création de l\'étiquette. Veuillez contacter le support.'
            }
        },
        async downloadEstimate() {

            this.estimateBtnLoading = true

            try {

                var response = await orderService.getEstimate(this.order.public_id, this.isMobile, this.merchant.invoice_prefix)

                this.estimateDownloaded = response;

                this.estimateBtnLoading = false
            }
            catch (e) {

                this.error = 'Une erreur est survenue lors de la création de l\'étiquette. Veuillez contacter le support.'
            }
        },
    },
    computed: {

        isMobile() {
            
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) === true
        },
        deliveryDelay() {

            let baseDays = this.order.coupon_type === 'IMMEDIAT' ? 5 : 7;
            if (this.completedAt === null) {

                return baseDays; 
            }

            let todaysDate = new Date()
            let timeDifference = todaysDate.getTime() -  this.completedAt.getTime()
            let daysDifference = timeDifference / (1000 * 3600 * 24)
            let left = baseDays - parseInt(daysDifference)

            if (left < 0) return 0

            if (left > baseDays) return baseDays

            return left
        },
        completedAt() {

            if (this.order.completed_at == undefined) return null

            return new Date(this.order.completed_at)
        },
    },
    watch: {

        isOrderLoaded() {

            this.$tracking.pageView('delivery', 'order', {'Order ID': this.orderId, 'Coupon Type': this.order.coupon_type})
        }
    }
}
</script>
<template>
    <basket-layout>
        <div v-if="isOrderLoaded">
            <div class="row mt-3 mt-md-5">
                <div class="col-md-8">
                    <div class="col-md-11">
                        <success-alert v-if="$route.query.t == 1" :message="'Votre bon de cession a bien été signé.'" />
                        <h3>Devis & Etiquette d'expédition</h3>
                        <p>Dernière étape, voici vos documents à télécharger.</p>
                        <div v-if="error" class="alert alert-danger" role="alert">
                            {{error}}
                        </div>
                        <div class="d-flex flex-column">
                            <div class="row">
                                <button class="btn bg-white border border-success m-3 col-9" :disabled="isDownloading" type="button" v-on:click="downloadEstimate()">
                                    <span class="text-success" v-if="!isMobile">Je télécharge mon devis</span>
                                    <span class="text-success" v-if="isMobile">Je reçois mon devis par email </span>
                                    <span v-if="estimateBtnLoading" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                                </button>
                                <i  v-if="estimateDownloaded" class="bi bi-check text-success col-1" style="font-size: 50px; opacity: 1;"></i>
                            </div>
                            <div class="row">
                                <button class="btn bg-white border border-success m-3 col-9" :disabled="isDownloading" type="button" v-on:click="downloadDeliveryNote()">
                                    <span class="text-success" v-if="!isMobile">Je télécharge l'étiquette d'expédition</span>
                                    <span class="text-success" v-if="isMobile">Je reçois par email l'étiquette d'expédition</span>
                                    <span v-if="deliveryBtnLoading" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                                </button>
                                <i  v-if="deliveryDownloaded" class="bi bi-check text-success col-1" style="font-size: 50px; opacity: 1;"></i>
                            </div>
                        </div>
                        <div class="my-4">
                           <p>Merci d'expédier votre colis <strong>sous {{ deliveryDelay }} jours.</strong></p>
                        </div>
                        <div class="my-4">
                            <router-link :to="{name: 'searchForm'}"><button  class="btn btn-success">Nouvelle Revente</button></router-link>
                        </div>
                        <div class="card my-4">
                            <div class="card-body row pb-2">
                                <div class="col-1 d-flex align-items-center justify-content-center">
                                    <i class="bi bi-question-circle text-success font-size-2rem"></i>
                                </div>
                                <div class="col">
                                    <p><strong>Comment emballer votre produit ?</strong>
                                    <br/>Retrouvez nos conseils pour bien emballer votre colis en <a :href="getFAQDeliveryURL()" target="_blank">cliquant ici</a>.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="offset-md-0 col-md-4">
                    <div class="card shadow-lg w-100">
                        <product-card :basket="order" />
                        <accepted-offer :price="order.price" :merchant="order.merchant" :type="order.coupon_type" :bonus="order.brand_campaign" :paymentType="order.payment_type" />
                    </div>
                    <orders-dashboard />
                </div>
            </div>
        </div>
    </basket-layout>
</template>

<script type="text/ecmascript-6">

export default {
    props: ['value', 'required'],
    data() {

        return {
            autocomplete: null
        }
    },
    metaInfo() {

        return {
            script: [{
                src: 'https://maps.googleapis.com/maps/api/js?key='+process.env.VUE_APP_GOOGLE_PLACES_KEY+'&libraries=places&v=weekly',
                async: true,
                defer: true,
                callback: () => this.initAutocomplete()
            }]
        }
    },
    methods: {
        initAutocomplete() {

            this.autocomplete = new window.google.maps.places.Autocomplete(
                document.getElementById("autocomplete"),
                { componentRestrictions: { country: 'fr'} }
            );

            this.autocomplete.setFields(["address_component"])

            this.autocomplete.addListener("place_changed", this.fillInAddress)
        },
        fillInAddress() {

            const place = this.autocomplete.getPlace()
            
            this.value.address1 = this.getFromPlace(place, ['street_number', 'route'])
            this.value.zipCode = this.getFromPlace(place, ['postal_code'])
            this.value.city = this.getFromPlace(place, ['locality'])
            this.value.country = this.getFromPlace(place, ['country'])
        },
        getFromPlace(place,  types) {

            let value = ''
            for (let type of types) {

                for (let component of place.address_components) {

                    if (component.types[0] == type) {

                        if (value == '')
                            value = value + component.long_name
                        else 
                            value = value + ' ' + component.long_name
                    }
                }
            }

            return value
        }
    }
}
</script>
<template>
    <section>
        <div class="form-group mt-3" id="locationField">
            <input :required="required == undefined ? false : required" class="form-control" id="autocomplete" placeholder="Entrer votre adresse postale" type="text" v-model="value.address1" />
        </div>
        <div class="form-group row mt-3">
            <div class="col"><input :required="required == undefined ? false : required" class="form-control" placeholder="Code postal" type="text" v-model="value.zipCode" /></div>
            <div class="col"><input :required="required == undefined ? false : required" class="form-control" placeholder="Ville" type="text" v-model="value.city" /></div>
            <div class="col"><input :required="required == undefined ? false : required" class="form-control" placeholder="Pays" type="text" v-model="value.country" /></div>
        </div>
    </section>
</template>

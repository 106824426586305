export default {

    methods: {

        getFAQURL() {

            let subdomain = window.location.hostname.split('.')[0];
            if (subdomain === 'fnac') {

                return 'https://fnac-greendid.crisp.help/fr/';
            }

            return 'https://greendid-faq.crisp.help/fr/';
        },

        getFAQDeliveryURL() {

            let subdomain = window.location.hostname.split('.')[0];
            if (subdomain === 'fnac') {
    
                return 'https://fnac-greendid.crisp.help/fr/article/comment-dois-je-emballer-mon-produit-3ow3pp/';
            }

            return 'https://greendid-faq.crisp.help/fr/article/comment-dois-je-emballer-mon-produit-vxio8c/';
        },

        getFAQContactURL() {

            let subdomain = window.location.hostname.split('.')[0];
            if (subdomain === 'fnac') {

                return 'https://fnac-greendid.crisp.help/fr/article/comment-puis-je-vous-contacter-si-jai-une-question-1ocuz9v/';
            }

            return 'https://greendid-faq.crisp.help/fr/article/comment-puis-je-vous-contacter-si-jai-une-question-1hfutt3/';
        },
        getContactEmail() {

            return 'contact-b2b@greendid.com';
        }
    }
}



export default {
    state: {
        source: null,
        merchantUserId: null
    },
    getters: {
        source: state => { return state.source },
        merchantUserId: state => { return state.merchantUserId },
    },
    mutations: {
        setSource (state, source) {
            
            if (source == undefined) {

                source = localStorage.getItem('source')
                state.source = source
            }
            else {

                localStorage.setItem('source', source)
                state.source = source
            }
        },
        setMerchantUserId (state, merchantUserId) {
            
            state.merchantUserId = merchantUserId
        },
    }
}

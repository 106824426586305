<script type="text/ecmascript-6">

import BasketLayout from '@/layouts/BasketV2.vue'
import GoogleAddress from '@/components/basket/Address.vue'
import merchantMixins from '@/mixins/merchant'

export default {

    mixins: {merchantMixins},
    components: {BasketLayout, GoogleAddress}, 
    data() {

        return {

            address: {
                address1: '',
                address2: '',
                city: '',
                zipCode: '',
                country: ''
            }
        }
    },
    methods: {

        makeDonation() {

            let href = 'https://www.ecosystem.eco/fr/recherche-point-de-collecte?profil=0&action=give&equipment-pro=none&equipment-part=363&type-pro=none&address-location='+this.address.address1.replace(' ', '+')+',+'+this.address.city+',+'+this.address.country
            this.$tracking.pageView('ecosystem donation', 'donation', {'Category': this.$route.query.cat})
            window.open(href, '_blank')
        }
    },
    created() {

        this.$tracking.pageView('greendid donation', 'donation', {'Category': this.$route.query.cat})
    }
}
</script>
<template>
    <basket-layout :withBreadCrumbs="false">
            <div class="row mt-3 mt-md-5">
                <div class="col-md-8">
                    <div class="col-md-11">
                        <h3>Recycler mon produit</h3>
                        <p>Nous n'avons pas d'offre pour votre produit. Si vous le souhaitez vous pouvez recycler votre produit grâce notre partenaire Ecosystem.</p>
                        <p>Pour cela, veuillez indiquer une adresse postale pour trouver le point de dépôt le plus proche. En cliquant sur "rechercher" vous serez redirigé vers le site de Ecosystem.</p>
                        <div class="card shadow-lg my-4">
                            <div class="card-body mx-sm-4">
                                <div class="row">
                                    <form @submit.prevent="makeDonation">
                                        <div class="form-group">
                                            <google-address v-model="address" />
                                        </div>
                                        <div class="form-group text-end mt-4">
                                            <button class="btn btn-info" type="submit">Rechercher</button> 
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="offset-md-0 col-md-4"/>
            </div>
    </basket-layout>
</template>

<script type="text/ecmascript-6">
export default {
    props: {
        'price': {
            'required': true
        },
        'isSelected': {
            'type': Boolean, 
            'default': false
        },
        'merchant': {
            'type' : Object
        }, 
    },
    methods: {

        selectVouch() {

            this.$emit('vouchSelected', { price: this.price, campaign: null, type: 'cash' })
        }
    },
    created() {

        if (this.isSelected) {

            this.selectVouch();
        }
    }
}
</script>

<template>
    <div class="d-flex flex-row mt-4 ms-3">
        <div class="card shadow-lg rounded-0 mx-0 col-md-7 col">
            <div class="row mx-0 h-100">
                <div class="col-1 px-0 row">
                    <div class="col-7 bg-success px-0"></div>
                    <div class="col-5 px-0"></div>
                </div>
                <div class="col-1 d-flex justify-content-center align-items-center px-0">
                    <input class="form-check-input" type="radio" id="no-campaign" name="campaign" @click="selectVouch" :checked="isSelected">
                </div>
                <div class="card-body col-10">
                    <p class="text-center mb-1">VIREMENT SUR VOTRE<br/><strong> {{merchant.coupon_name.toUpperCase()}}</strong></p>
                    <h2 class="text-success justify-content-center d-flex"><strong>{{price | currency}}</strong></h2>
                    <p class="font-size-xxs mb-0 mx-3 text-gd-faint">
                        Sous réserve de conformité de votre produit avec votre déclaratif.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
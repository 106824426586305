import Basket from '../views/basket/Basket.vue'
import AcceptOffer from '../views/basket/AcceptOffer.vue'
import Error404 from '../views/basket/Error404.vue'
import authGuard from './guards/auth.js'
import SignIn from '../views/basket/SignIn.vue'
import Delivery from '../views/basket/Delivery.vue'
import Confirm from '../views/basket/Confirm.vue'
import Refused from '../views/basket/Refused.vue'
import CounterOffer from '../views/basket/CounterOffer.vue'
import CounterOfferRefused from '../views/basket/CounterOfferRefused.vue'
import Sent from '../views/basket/Sent.vue'
import ToValidate from '../views/basket/ToValidate.vue'
import Expired from '../views/basket/Expired.vue'
import StandBy from '@/views/basket/StandBy.vue'
import SearchForm from '@/views/basket/SearchForm.vue'

export default [
    {
        path: '/basket/:id',
        name: 'basketInfo',
        component: Basket,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/basket/:id/acceptOffer',
        name: 'basketAcceptOffer',
        component: AcceptOffer,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/order/:id/delivery',
        name: 'orderDelivery',
        component: Delivery,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/error404',
        name: 'basketError404',
        component: Error404
    },
    {
        path: '/basket/:id/signin',
        name: 'basketSignIn',
        component: SignIn
    },
    {
        path: '/order/:id/confirm',
        name: 'orderConfirm',
        component: Confirm,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/order/:id',
        name: 'order',
        redirect: {name: 'orderConfirm'},
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/order/:id/return',
        name: 'orderRefused',
        component: Refused,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/order/:id/counterOffer',
        name: 'orderCounterOffer',
        component: CounterOffer,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/order/:id/counterOfferRefused',
        name: 'orderCounterOfferRefused',
        component: CounterOfferRefused,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/order/:id/sent',
        name: 'orderSent',
        component: Sent,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/order/:id/toValidate',
        name: 'orderToValidate',
        component: ToValidate,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/order/:id/expired',
        name: 'orderExpired',
        component: Expired,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/order/:id/standBy',
        name: 'orderStandBy',
        component: StandBy,
        beforeEnter: authGuard.ifAuthenticated
    },
    {
        path: '/searchForm',
        name: 'searchForm',
        component: SearchForm,
        beforeEnter: authGuard.ifAuthenticated
    }
]

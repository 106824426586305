import axios from 'axios'

export default {

    create(
        productId,
        merchantId,
        merchantProductId,
        merchantUserId,
        merchantOrderId,
        merchantProductImg,
        merchantOrderDate,
        merchantProductPrice,
        conditions,
        basketPublicId,
        invoiceFilename,
        brandCampaign,
        offerTemplateId,
        specsId,
        specs,
        basketType,
        paymentType,
        sourceSite,
        listingPublicId
    ) {

        var path = '/api/seller/order'
        var data = {
            productId: productId,
            merchantId: merchantId,
            merchantProductId: merchantProductId,
            merchantUserId: merchantUserId,
            merchantOrderId: merchantOrderId,
            merchantProductImg: merchantProductImg,
            merchantOrderDate: merchantOrderDate,
            merchantProductPrice: merchantProductPrice,
            conditions: conditions,
            basketPublicId: basketPublicId,
            invoiceFilename: invoiceFilename,
            brandCampaignPublicId: brandCampaign ? brandCampaign.public_id : null,
            offerTemplateId: offerTemplateId,
            specsId: specsId,
            specs: specs,
            basketType: basketType,
            paymentType: paymentType,
            sourceSite: sourceSite,
            listingPublicId: listingPublicId
        }

        return axios.post(path, data)
    },
    get(orderPublicId, withCanBeImmediat = false) {

        var path = '/api/seller/order/' + orderPublicId
        if (withCanBeImmediat === true) {

            path = path + '?c=1'
        }

        return axios.get(path);

    },
    getAll() {

        var path = '/api/seller/orders'

        return axios.get(path);
    },
    async getDeliveryNote(orderPublicId, isMobile) {

        var path = '/api/seller/order/' + orderPublicId + '/delivery'

        if (isMobile) {

            path = path + '?mobile=1'
        }
        else {

            path = path + '?mobile=0'
        }

        return axios.get(path)
    },
    async getEstimate(orderPublicId, isMobile, merchantPrefix) {

        var path = '/api/seller/order/' + orderPublicId + '/estimate'
        var downloaded = null;

        if (isMobile) {

            path = path + '?mobile=1'
        }
        else {

            path = path + '?mobile=0'
        }

        await axios.get(path, {

            responseType: 'blob' 
        })
        .then(response => {
        
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'estimate_'+merchantPrefix+'_'+orderPublicId+'.pdf'; // PRIMO-ORDER
            link.click();
            window.URL.revokeObjectURL(url);

            downloaded = true
        })
        .catch(error => {

            console.log(error);
            downloaded = false
        });
        
        return downloaded;
    },
    updateCounterOffer(orderPublicId, counterOfferAccepted) {

        const path = '/api/seller/orders/' + orderPublicId + '/counterOffer?accepted=' + counterOfferAccepted

        return axios.patch(path);
    }
}

import axios from 'axios'

export default {

    createPaymentAccountLink(businessType, type, returnUrl, refreshUrl) {

        var path = '/api/seller/payment/account'

        var data = {

            businessType: businessType,
            type: type,
            returnUrl: returnUrl,
            refreshUrl: refreshUrl,
        }

        return axios.post(path, data)
    }
}

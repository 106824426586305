<script type="text/ecmascript-6">

import BasketLayout from '../../layouts/BasketV2.vue'
import orderMixin from '@/mixins/order'
import merchantMixin from '@/mixins/merchant'
import FaqMixin from '@/mixins/faq'
import AcceptedOffer from '../../components/basket/AcceptedOfferV2.vue'
import ProductCard from '../../components/basket/ProductCardV2.vue'
import MerchantLink from '@/components/basket/MerchantLink.vue'
import OrdersDashboard from '@/components/basket/OrdersDashboard.vue'
import stripeService from '@/services/stripe.js'

export default {

    mixins: [orderMixin, merchantMixin, FaqMixin],
    components: {BasketLayout, AcceptedOffer, ProductCard, MerchantLink, OrdersDashboard}, 
    data() {
        return {

            businessType: 'company',
            type: 'express',
            returnUrl: '',
            refreshUrl: '',
            isLoading: false,
        }
    },
    created() {
      
        this.$store.commit('setCurrentStep', 50)
        this.returnUrl = 'https://' + window.location.hostname + this.$route.fullPath
        this.refreshUrl = 'https://' + window.location.hostname + this.$route.fullPath
    },
    watch: {

        isOrderLoaded() {

            this.$tracking.pageView('confirm', 'order', {'Order ID': this.orderId, 'Coupon Type': this.order.coupon_type})
        }
    },
    computed: {

        deliveryDelay() {  

            let baseDays = this.order.coupon_type === 'IMMEDIAT' ? 5 : 7;
            if (this.completedAt === null) {

                return baseDays; 
            }

            let todaysDate = new Date()
            let timeDifference = todaysDate.getTime() -  this.completedAt.getTime()
            let daysDifference = timeDifference / (1000 * 3600 * 24)
            let left = baseDays - parseInt(daysDifference)

            if (left < 0) return 0

            if (left > baseDays) return baseDays

            return left
        },
        completedAt() {

            if (this.order.completed_at == undefined) return null

            return new Date(this.order.completed_at)
        },
        displayIBANLink() {

            return this.order != undefined && this.order.payment_type == 'cash'
        }
        
    },
    methods: {

        async createPaymentAccountLink() {

            this.isLoading = true
            var response = await stripeService.createPaymentAccountLink(this.businessType, this.type, this.returnUrl, this.refreshUrl)

            if (response) {

                window.location.href = response.data
                this.isLoading = false
            }
        }
    }   
}
</script>
<template>
    <basket-layout>
        <div v-if="isOrderLoaded">
            <div class="row mt-3 mt-md-5">
                <div class="col-md-8">
                    <div v-if="order.order_status_id != 21" class="col-md-11 mb-4">
                        <h3>Félicitations !<br/>Votre revente est prête.</h3>
                        <p>Merci <strong>d'expédier votre produit sous {{deliveryDelay}} jour(s).</strong><br>Passé ce délai votre produit pourra être refusé.</p>
                        <p>Vous pourrez suivre l'évolution de votre vente directement depuis votre espace client.</p>
                        
                        <button v-if="merchant.is_b2b_marketplace" :disabled="isLoading" @click="createPaymentAccountLink" style="background-color: #635BFF;color:white" class="btn ms-0 me-3 my-sm-4">Configurer mon compte Stripe<span v-if="isLoading" class="spinner-border spinner-border-sm mr-2 ms-2" role="status" aria-hidden="true"></span></button>
                        
                        <merchant-link :disabled="isLoading" :merchant="merchant" />
                        <div v-if="order.coupon_type == 'IMMEDIAT' && order.user.validated_identity_proofs_count > 0" class="card shadow-lg border-success mt-4">
                            <div class="card-body py-2">
                                <p><strong class="text-success">Bonne nouvelle !</strong> <img class="ms-2" src="/assets/img/icon-bon-immediat.svg" width="35" height="35"/></p>
                                <p>Vous avez choisi la <strong class="text-success">reprise immédiate</strong> et vous avez fourni tous les éléments nécessaires à sa création.</p>
                                <p><strong class="text-success">Vous allez recevoir votre {{order.merchant.coupon_name}} d’ici 5 minutes environ</strong>.</p>
                            </div>
                        </div>
                        <div v-if="order.coupon_type == 'IMMEDIAT' && order.user.validated_identity_proofs_count == 0 && order.user.active_identity_proofs_count == 0" class="card shadow-lg border-danger mt-4">
                            <div class="card-body py-2">
                                <p><strong class="text-danger">ATTENTION</strong></p>
                                <p><strong class="text-danger">Vous n’avez pas encore fourni de pièce d’identité valide</strong>.</p>
                                <p>Dès que vous aurez fourni une pièce d’identité valide, vous recevrez votre {{order.merchant.coupon_name}} (délai de 5 minutes environ).</p>
                                <p>Vous pouvez à tout moment fournir votre pièce d’identité en vous connectant à votre compte GREENDID ou nous l'envoyer par email à {{ getContactEmail() }}.</p>
                                <p><router-link :to="{name: 'orderKyc', params: {id: order.public_id}, query: {k: 1}}" class="btn btn-danger">Fournir une pièce d'identité valide</router-link></p>
                            </div>
                        </div>
                        <div v-if="order.coupon_type == 'IMMEDIAT' && order.user.validated_identity_proofs_count == 0 && order.user.active_identity_proofs_count >= 1" class="card shadow-lg border-danger mt-4">
                            <div class="card-body py-2">
                                <p><strong>Votre carte d'identité est en cours de validation.</strong></p>
                                <p>Vous allez recevoir votre {{order.merchant.coupon_name}} une fois la validation effectuée.</p>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="order.order_status_id === 21">
                        <h3>Colis perdu</h3>
                        <p>Votre colis a été perdu par le transporteur. Nous allons gérer ce problème directement avec le transporteur.
                            <br/>
                            <strong v-if="order.coupon_type == 'POSTPONED' && order.price > 0">Vous allez recevoir votre {{order.merchant.coupon_name}} d'ici quelques minutes.</strong>
                            <strong v-if="order.coupon_type == 'IMMEDIAT' && order.price > 0">Vous pouvez utiliser votre {{order.merchant.coupon_name}}, vous ne serez pas débité.</strong>
                        </p>
                        <p>Vous pouvez suivre l'évolution de votre vente directement depuis votre espace client.</p>
                        <merchant-link :merchant="merchant" />
                    </div>
                    <div class="card mt-4">
                        <div class="card-body row pb-2">
                            <div class="col-1">
                                <i class="bi bi-question-circle text-success font-size-2rem"></i>
                            </div>
                            <div class="col">
                                <p>Si vous avez une question, vous pouvez :</p>
                                <ul>
                                    <li>consulter notre <a :href="getFAQURL()" target="_blank">FAQ</a></li>
                                    <li>nous contacter par tchat (accessible en bas à droite)</li>
                                    <li>envoyer un email à <a :href="'mailto:' + getContactEmail()">{{ getContactEmail() }}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="offset-md-0 col-md-4">
                    <div class="card shadow-lg w-100">
                        <product-card :basket="order" />
                        <accepted-offer :price="order.price" :merchant="order.merchant" :type="order.coupon_type" :bonus="order.brand_campaign" :paymentType="order.payment_type" />
                    </div>
                    <orders-dashboard />
                </div>
            </div>
        </div>
    </basket-layout>
</template>

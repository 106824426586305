import axios from 'axios'

export default {

    getCSRF() {
 
        var path = '/sanctum/csrf-cookie'

        return axios.get(path);
    },
    login(email, password, merchantId) {

        var path = '/api/login'
        var data = {
            email: email,
            password: password,
            app: 'b2b',
            merchantId: merchantId
        }

        return axios.post(path, data)
    },
    register(email, password, firstName, lastName, seller, phoneNumber, merchantId) {

        var path = '/api/seller/register'

        var data = {

            email: email,
            password: password,
            lastName: lastName,
            firstName: firstName,
            phoneNumber: phoneNumber,
            seller: seller,
            merchantId: merchantId,
        }

        return axios.post(path, data)
    },
    logout() {

        var path = 'api/logout'

        return axios.get(path)
    },
    getCurrentUser() {

        var path = '/api/user'

        return axios.get(path)
    },
    forgotPassword(email, merchantId) {

        var path = '/api/forgot-password'
        var data = {
            email: email,
            app: 'b2b',
            merchantId: merchantId
        }

        return axios.post(path, data)
    },
    resetPassword(token, email, password, passwordConfirmation, merchantId) {

        var path = '/api/reset-password'
        var data = {
            token: token,
            email: email,
            password: password,
            password_confirmation: passwordConfirmation,
            app: 'b2b',
            merchantId: merchantId
        }

        return axios.post(path, data)
    }
}

<script type="text/ecmascript-6">

import BasketLayout from '../../layouts/BasketV2.vue'
import orderMixin from '../../mixins/order'
import merchantMixin from '@/mixins/merchant'
import CounteredOffer from '../../components/basket/CounteredOffer.vue'
import orderService from '../../services/order'
import StandardModal from '../../components/StandardModalV2.vue'
import ProductCard from '../../components/basket/ProductCardV2.vue'
import OrdersDashboard from '@/components/basket/OrdersDashboard.vue'

export default {

    components: {BasketLayout, CounteredOffer, StandardModal, ProductCard, OrdersDashboard},
    mixins: [orderMixin, merchantMixin],
    data() {

        return {
            isAcceptLoading: false,
            isRefuseLoading: false,
            tryToRefuse: false,
            tryToAccept: false,
        }
    },
    created() {
      
        this.$tracking.pageView('counter offer', 'order', {'Order ID': this.orderId})
        this.$store.commit('setCurrentStep', 50)
    },
    methods: {
        async accept() {

            this.isAcceptLoading = true
            try {

                await orderService.updateCounterOffer(this.order.public_id, 1)  
                this.$router.push({name: 'orderTransfer'}) 
            }
            catch (e) {

                this.$router.go()
            }

            this.isAcceptLoading = false
        },
        async refuse() {

            try {

                await orderService.updateCounterOffer(this.order.public_id, 0)  
                this.$router.push({name: 'orderCounterOfferRefused'})
            }
            catch (e) {

                this.$router.go()
            }
        }
    }
}
</script>
<style scoped>
    .btn-outline-danger {
        background-color: white !important;
    }
    .btn-outline-danger:hover {
        background-color: #f34770 !important;
    }
</style>
<template>
    <basket-layout :withBreadCrumbs="false">
        <div v-if="isOrderLoaded">
            <div class="row mt-3 mt-md-5">
                <div class="col-md-8">
                    <div class="col-md-11">
                        <h3>Contre-Offre</h3>
                        <p class="mt-4">Suite au diagnostic de votre produit, notre partenaire {{order.reseller.name}} indique que <strong>son état n'est pas conforme à votre déclaratif.</strong></p>
                        <p>Voici la raison :<br/>« {{order.active_counter_offer.reason}} »<br/><i>Technicien de l'atelier de {{order.reseller.name}}</i></p>
                        <p class="mb-0">Nous vous proposons par conséquent de revoir le prix de reprise de votre produit à</p>
                        <h5><strong class="text-success">{{order.price | currency}}.</strong></h5>
                        <div class="row d-flex justify-content-center my-5 px-3">
                            <button :disabled="isAcceptLoading || isRefuseLoading" role="button" class="btn btn-success col-7" @click="order.coupon_type == 'IMMEDIAT' ? tryToAccept = true : accept()">
                                <span v-if="isAcceptLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                J'accepte la contre-offre <br class="d-md-none">de {{order.price | currency}}
                            </button>
                            <button :disabled="isAcceptLoading || isRefuseLoading" role="button" class="btn btn-danger offset-1 col-4" @click="tryToRefuse = true">
                                <span v-if="isRefuseLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                Je refuse
                            </button>
                        </div>
                    </div>
                </div>
                <div class="offset-md-0 col-md-4">
                    <div class="card shadow-lg w-100">
                        <product-card :basket="order" />
                        <countered-offer :counter="order.active_counter_offer" />
                    </div>
                    <orders-dashboard />
                </div>
            </div>
        </div>
        <standard-modal v-if="tryToAccept" :closeLabel="'Annuler'" @close="tryToAccept = false">
            <template v-slot:body>
                <div class="row d-flex align-items-center mb-4">
                    <h4 class="col-5 pe-0 mb-0">{{order.merchant.coupon_name}} - <span class="text-success">IMMEDIAT</span></h4>
                    <img class="col-1 pe-4 ps-0" src="/assets/img/icon-bon-immediat.svg"/>
                </div>
                <div>
                    <p>Vous avez choisi le <strong>{{order.merchant.coupon_name}} - immédiat</strong><br/>Vous avez donc déjà reçu votre {{order.merchant.coupon_name}} d'un montant de {{order.active_counter_offer.from_price | currencyInteger}}</p>
                    <p><strong>Suite à votre accord de la contre-offre de {{order.active_counter_offer.to_price | currencyInteger}}</strong> pour votre produit, nous allons procéder au prélèvement sur votre carte bancaire de la somme de <span class="text-danger">{{order.active_counter_offer.from_price - order.active_counter_offer.to_price | currencyInteger}}.</span></p>
                    <p>Il s'agit de la différence entre la nouvelle offre de {{order.active_counter_offer.to_price | currencyInteger}} et votre {{order.merchant.coupon_name}} de {{order.active_counter_offer.from_price | currencyInteger}} que vous avez déjà reçu.</p>
                </div>
                <div class="row d-flex justify-content-center mt-4">
                    <button role="button" class="btn btn-success col-5" @click="accept">J'accepte la <br class="d-md-none">contre-offre</button>
                    <button role="button" class="btn btn-outline-danger offset-1 col-5" @click="tryToAccept = false">J'ai changé <br class="d-md-none">d'avis</button>
                </div>
                <div class="row d-flex justify-content-center text-center mt-1 mb-3">
                    <span class="col-5 small">J'accepte le prélèvement de {{order.active_counter_offer.from_price - order.active_counter_offer.to_price | currencyInteger}}</span>
                    <span class="offset-1 col-5 small"></span>
                </div>
            </template>
        </standard-modal>
        <standard-modal v-if="tryToRefuse" :closeLabel="'Annuler'" @close="tryToRefuse = false">
            <template v-slot:body>
                <div>
                    <p>Etes vous sûr de vouloir refuser la contre offre de <strong>{{order.active_counter_offer.to_price | currencyInteger}}</strong> ?</p>
                    <p>En cas de refus, afin de récupérer votre produit, vous devrez payer <strong>10 €</strong> pour les frais de retour.</p>
                </div>
                <div class="row d-flex justify-content-center mt-4">
                    <button role="button" class="btn btn-success col-5" @click="refuse">Je refuse la <br class="d-md-none">contre-offre</button>
                    <button role="button" class="btn btn-outline-danger offset-1 col-5" @click="tryToRefuse = false">J'ai changé <br class="d-md-none">d'avis</button>
                </div>
           
            </template>
        </standard-modal>
    </basket-layout>
</template>
export default {

    state: {
        experiments: {
            'calibration': null
        }
    },
    getters: {
        experimentValue: (state) => (name) => { return state.experiments[name] },
        experiments: state => { return state.experiments }
    },
    mutations: {
        initExperiments(state) {

            for (const [key, value] of Object.entries(state.experiments)) {
                
                if (value == undefined) {
                    
                    let experimentName = 'greendid_experiment_' + key
                    let experimentValue = localStorage.getItem(experimentName)
                    
                    if (experimentValue == undefined) {

                        let experimentValues = ['A', 'B']
                        let random = Math.floor(Math.random() * experimentValues.length)
                        experimentValue = experimentValues[random]
                        localStorage.setItem(experimentName, experimentValue)
                    }
                    
                    state.experiments[key] = experimentValue
                }
            }
        }
    }
}
